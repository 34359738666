import React from 'react';

const ComplianceTable = ({complianceItems}) => (
  <div className="mt4 mb4 mt6-l mb6-l pl4 pr4 mw8 center">
    {complianceItems.map(complianceItem => (
      <div className="flex-l mt3 mb3 pt3 pb3 bb w-100 justify-between items-center">
        <div className="w-100 w-60-l">
          <h4 className="f3 lh-copy mt0 mb3">{complianceItem.title.text}</h4>
          <div dangerouslySetInnerHTML={{__html: complianceItem.text.html}} className="f5 lh-copy grey" />
        </div>
        <div className="w-100 w-30-l">
          {complianceItem.pdf && (
            <a href={complianceItem.pdf.url} className="link buttonlink yellow button semi-bold ttu f6 flex items-center tracked mt4 mr0 justify-end-l" target="_blank" rel="noopener noreferrer">
              Download PDF
            </a>
          )}
        </div>
      </div>
    ))}
  </div>
);

export default ComplianceTable;
