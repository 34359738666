import React from 'react';
import {graphql} from 'gatsby';
import Layout from '../components/layout';
import SEO from '../components/seo';
import PageBanner from '../components/PageBanner';
import IntroBasic from '../components/IntroBasic';
import ComplianceTable from '../components/ComplianceTable';

const CompliancePage = ({data: {prismicCompliancePage}}) => {
  const {data} = prismicCompliancePage;
  return (
    <Layout>
      <SEO title={data.page_title.text} keywords={[`gatsby`, `application`, `react`]} />
      <PageBanner bannerImage={data.page_banner} pageTitle={data.page_title.text} />
      <IntroBasic intro={data.intro} />
      <ComplianceTable complianceItems={data.compliance_items} />
    </Layout>
  );
};

export const pageQuery = graphql`
  query {
    prismicCompliancePage {
      data {
        page_banner {
          localFile {
            childImageSharp {
              fluid(maxWidth: 1800, quality: 90) {
                ...GatsbyImageSharpFluid_withWebp
              }
            }
          }
        }
        page_title {
          text
        }
        intro {
          text
        }
        compliance_items {
          title {
            text
          }
          text {
            html
          }
          pdf {
            url
          }
        }
      }
    }
  }
`;

export default CompliancePage;
